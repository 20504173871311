<template>
  <b-container
    fluid
    class="mb-5"
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="schedule_type">Filter by Schedule Type</label>
            <b-select
              id="schedule_type"
              v-model="filter.scheduleType"
              :options="list.scheduleTypes"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date">Filter By Date</label>
            <b-input
              id="date"
              v-model="filter.date"
              type="date"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <div class="form-group">
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </div>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            autocomplete="off"
            @click="onShowNewForm"
          >
            New Driver Schedule
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
        hover
        responsive
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>
        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>
        <template
          #cell(actions)="row"
        >
          <b-button
            v-if="row.item.actual_arrival_at == null"
            size="sm"
            @click="onShowEditForm(row.item)"
          >
            Actual
          </b-button>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-driver-schedule-form"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      @ok="onConfirmAction"
    >
      <b-card no-body>
        <ValidationObserver ref="form">
          <form
            novalidate
            role="form"
          >
            <ValidationProvider
              #default="{ errors }"
              name="departure date"
              vid="departure_date"
              rules="required"
            >
              <b-form-group>
                <label for="departure_date">Departure Date</label>
                <b-input
                  id="departure_date"
                  v-model="driverSchedule.departure_date"
                  type="date"
                  :state="errors.length > 0 ? false : null"
                  :disabled="state.busy || state.fetching || state.editing"
                  @change="onParameterChange"
                />
                <div
                  v-if="errors.length > 0"
                  class="invalid-feedback"
                >
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              #default="{ errors }"
              name="departure time"
              vid="departure_time"
              rules="required"
            >
              <b-form-group>
                <label for="departure_time">Departure Time</label>
                <b-input
                  id="departure_time"
                  v-model="driverSchedule.departure_time"
                  type="time"
                  :state="errors.length > 0 ? false : null"
                  :disabled="state.busy || state.fetching || state.editing"
                  @change="onParameterChange"
                />
                <div
                  v-if="errors.length > 0"
                  class="invalid-feedback"
                >
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              #default="{ errors }"
              name="arrival date"
              vid="arrival_date"
              rules="required"
            >
              <b-form-group>
                <label for="arrival_date">Arrival Date</label>
                <b-input
                  id="arrival_date"
                  v-model="driverSchedule.arrival_date"
                  type="date"
                  :state="errors.length > 0 ? false : null"
                  :disabled="state.busy || state.fetching || state.editing"
                  @change="onParameterChange"
                />
                <div
                  v-if="errors.length > 0"
                  class="invalid-feedback"
                >
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              #default="{ errors }"
              name="arrival time"
              vid="arrival_time"
              rules="required"
            >
              <b-form-group>
                <label for="arrival_time">Arrival Time</label>
                <b-input
                  id="arrival_time"
                  v-model="driverSchedule.arrival_time"
                  type="time"
                  :state="errors.length > 0 ? false : null"
                  :disabled="state.busy || state.fetching || state.editing"
                  @change="onParameterChange"
                />
                <div
                  v-if="errors.length > 0"
                  class="invalid-feedback"
                >
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>

            <div v-if="!state.editing">
              <ValidationProvider
                #default="{ errors }"
                name="driver"
                vid="driver"
                rules="required"
              >
                <b-form-group>
                  <label for="name">Driver (Type any string to search)</label>
                  <v-select
                    id="name"
                    v-model="selected.driver"
                    type="text"
                    :class="[errors.length > 0 ? ' is-invalid' : '']"
                    :options="list.drivers"
                    label="name"
                    :disabled="state.busy || state.fetching"
                  >
                    <template #option="{ name }">
                      <strong>{{ name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Driver
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </div>
            <div>
              <b-form-group>
                <label for="driver_name_edit">Driver Name</label>
                <b-input
                  id="driver_name_edit"
                  v-model="driverSchedule.driver_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </div>
            <b-form-group>
              <label for="driver_license_type">License Type</label>
              <b-input
                id="driver_license_type"
                v-model="driverSchedule.driver_license_type"
                type="text"
                :disabled="true"
              />
            </b-form-group>

            <b-form-group>
              <label for="driver_license">License Number</label>
              <b-input
                id="driver_license"
                v-model="driverSchedule.driver_license"
                type="text"
                :disabled="true"
              />
            </b-form-group>

            <b-form-group>
              <label for="driver_license_expiry">License Expiry</label>
              <b-input
                id="driver_license_expiry"
                v-model="driverSchedule.driver_license_expiry"
                type="text"
                :disabled="true"
              />
            </b-form-group>

            <div v-if="state.editing">
              <ValidationProvider
                #default="{ errors }"
                name="actual arrival date"
                vid="actual_arrival_date"
                :rules="actualArrivalRules"
              >
                <b-form-group>
                  <label for="actual_arrival_date">Actual Arrival Date</label>
                  <b-input
                    id="actual_arrival_date"
                    v-model="driverSchedule.actual_arrival_date"
                    type="date"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                #default="{ errors }"
                name="actual arrival time"
                vid="actual_arrival_time"
                rules="required"
              >
                <b-form-group>
                  <label for="actual_arrival_time">Actual Arrival Time</label>
                  <b-input
                    id="actual_arrival_time"
                    v-model="driverSchedule.actual_arrival_time"
                    type="time"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </div>
          </form>
        </ValidationObserver>
      </b-card>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, LogisticsOfficerScheduleDriverService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'LogisticsOfficerScheduleDrivers',

  middleware: ['auth', 'logistics-officer'],

  metaInfo () {
    return {
      title: 'Schedule Drivers'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        editing: false,
        busy: false,
        fetching: false
      },
      list: {
        drivers: [],
        scheduleTypes: [
          { text: 'Departure', value: 'departure_at' },
          { text: 'Arrival', value: 'arrival_at' }
        ]
      },
      filter: {
        scheduleType: 'departure_at',
        date: this.dateFormatter(Date.now())
      },
      selected: {
        driver: null
      },
      driverSchedule: {
        id: 0,
        driver: '',
        driver_name: '',
        driver_license_type: '',
        driver_license: '',
        driver_license_expiry: '',
        departure_date: '',
        departure_time: '',
        arrival_date: '',
        arrival_time: '',
        actual_arrival_date: '',
        actual_arrival_time: ''
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'driver_name' },
          { key: 'departure_at', formatter: this.dateTimeFormatterLong, sortable: true },
          { key: 'arrival_at', formatter: this.dateTimeFormatterLong, sortable: true },
          { key: 'actual_arrival_at', label: 'actual arrival', formatter: this.dateTimeFormatterLong }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Edit Actual Arrival' : 'New Driver Schedule'
    },
    actualArrivalRules () {
      return 'required'
    }
  },

  watch: {
    'selected.driver' (value) {
      if (value) {
        this.driverSchedule.driver = value.id
        this.driverSchedule.driver_name = value.name
        this.driverSchedule.driver_license_type = value.license_type
        this.driverSchedule.driver_license = value.license_number
        this.driverSchedule.driver_license_expiry = value.license_expiry
      } else {
        this.driverSchedule.driver = 0
        this.driverSchedule.driver_name = ''
        this.driverSchedule.driver_license_type = ''
        this.driverSchedule.driver_license = ''
        this.driverSchedule.driver_license_expiry = ''
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await LogisticsOfficerScheduleDriverService.get(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}&filter_schedule_type=${this.filter.scheduleType}&filter_date=${this.filter.date}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    onFilterChange () {
      this.$refs.table.refresh()
    },

    onParameterChange () {
      this.fetchAvailableDrivers(this)
    },

    fetchAvailableDrivers: _.debounce(async vm => {
      if (vm.driverSchedule.departure_date !== '' && vm.driverSchedule.departure_time !== '' && vm.driverSchedule.arrival_date !== '' && vm.driverSchedule.arrival_time !== '') {
        const filter = `departure_date=${vm.driverSchedule.departure_date}&departure_time=${vm.driverSchedule.departure_time}&arrival_date=${vm.driverSchedule.arrival_date}&arrival_time=${vm.driverSchedule.arrival_time}`
        vm.list.drivers = []
        vm.state.fetching = true
        await SharedListService.getAvailableDrivers(filter).then(({ data }) => {
          vm.list.drivers = data
          vm.selected.driver = null
          vm.state.fetching = false
        }).catch(() => {
          vm.state.fetching = false
        })
      }
    }, 500),

    onShowNewForm () {
      this.state.editing = false
      this.list.drivers = []
      this.selected.driver = null
      this.driverSchedule.id = 0
      this.driverSchedule.driver = 0
      this.driverSchedule.driver_name = ''
      this.driverSchedule.driver_license_type = ''
      this.driverSchedule.driver_license = ''
      this.driverSchedule.driver_license_expiry = ''
      this.driverSchedule.departure_date = this.filter.date
      this.driverSchedule.departure_time = ''
      this.driverSchedule.arrival_date = this.filter.date
      this.driverSchedule.arrival_time = ''
      this.$bvModal.show('modal-driver-schedule-form')
    },

    onShowEditForm (item) {
      this.state.editing = true
      const departureDate = item.departure_at?.split(' ')[0]
      const departureTime = item.departure_at?.split(' ')[1]
      const arrivalDate = item.arrival_at?.split(' ')[0]
      const arrivalTime = item.arrival_at?.split(' ')[1]
      const actualArrivalDate = item.actual_arrival_at?.split(' ')[0]
      const actualArrivalTime = item.actual_arrival_at?.split(' ')[1]
      this.driverSchedule.id = item.id
      this.driverSchedule.driver = item.driver
      this.driverSchedule.driver_name = item.driver_name
      this.driverSchedule.driver_license_expiry = item.driver_license_expiry
      this.driverSchedule.driver_license = item.driver_license
      this.driverSchedule.driver_license_type = item.driver_license_type
      this.driverSchedule.departure_date = departureDate
      this.driverSchedule.departure_time = departureTime
      this.driverSchedule.arrival_date = arrivalDate
      this.driverSchedule.arrival_time = arrivalTime
      this.driverSchedule.actual_arrival_date = actualArrivalDate || arrivalDate
      this.driverSchedule.actual_arrival_time = actualArrivalTime?.toString().substring(0, 5) || arrivalTime.toString().substring(0, 5)
      this.$bvModal.show('modal-driver-schedule-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          let title = 'Do you really want to create driver schedule?'
          if (this.state.editing) {
            title = 'Do you really want to save changes?'
          }
          this.$swal.fire({
            icon: 'question',
            title: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (this.state.editing) {
                return this.onFormPutSubmit()
              } else {
                return this.onFormPostSubmit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oppss! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        LogisticsOfficerScheduleDriverService.post(this.driverSchedule).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-driver-schedule-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        LogisticsOfficerScheduleDriverService.put(this.driverSchedule).then(({ data }) => {
          this.$bvModal.hide('modal-driver-schedule-form')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.table.localItems, { id: this.driverSchedule.id })
            row.actual_arrival_at = data.driverSchedule.actual_arrival_at
            row.updated_at = data.driverSchedule.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    }
  }
}
</script>
